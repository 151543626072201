// src/utils/getImages.ts
export interface ImageItem {
  folder: string;
  img: string;
}

export const getFolders = async (): Promise<ImageItem[]> => {
  const response = await fetch('/api/getFolders');
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data.folders;
};

export const getImages = async (folder: string): Promise<string[]> => {
  const response = await fetch(`/api/getImages/${folder}`);
  if (!response.ok) {
    throw new Error('Network response was not ok');
  }
  const data = await response.json();
  return data.imageNames.map((imageName: string) => `/assets/images/${folder}/${imageName}`);
};
