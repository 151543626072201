// src/themes/theme.ts
import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  styles: {
    global: {
      'html, body': {
        margin: '0',
        padding: '0',
        fontFamily: 'Academy Engraved',
        height: '100%',
      },
    },
  },
  colors: {
    purple: {
      default: '#8A3463',
      dark: '#483248',
      light: '#E6E6FA',
    },
    cinammon: {
      lighter: '#fffff1',
      light: '#eadabc',
      default: '#d4b588',
      dark: '#bf9053',
      darker: '#a96b1e',
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'full',
      },
      sizes: {
        md: {
          fontSize: 'clamp(1rem, 1.5vw, 2rem)', // Use clamp for responsive font size
          bg: 'cinammon.light',
          color: 'cinammon.dark',
          _hover: { color: 'cinammon.darker' },
        },
      },
      defaultProps: {
        size: 'md', // Default button size is medium
        variant: 'navButton', // Set the default variant to navButton
      },
    },
    Text: {
      variants: {
        siteTitleText: {
          fontSize: '4xl',
          fontWeight: 'bold',
          color: 'cinammon.lighter',
          textAlign: 'center',
          whiteSpace: 'nowrap',
        },
        titleText: {
          fontSize: '3xl',
          fontWeight: 'bold',
          color: 'cinammon.default',
        },
      },
    },
  },
});

export default theme;
