// src/lib/context/AspectRatioContext.tsx
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

// Define the type for the context
interface AspectRatioContextType {
  aspectRatio: number;
}

// Create the context with a default value
const AspectRatioContext = createContext<AspectRatioContextType | undefined>(undefined);

// Define the type for the provider's props
interface AspectRatioProviderProps {
  children: ReactNode;
}

export const AspectRatioProvider: React.FC<AspectRatioProviderProps> = ({ children }) => {
  const [aspectRatio, setAspectRatio] = useState<number>(window.innerWidth / window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setAspectRatio(window.innerWidth / window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <AspectRatioContext.Provider value={{ aspectRatio }}>
      {children}
    </AspectRatioContext.Provider>
  );
};

export const useAspectRatio = (): number => {
  const context = useContext(AspectRatioContext);
  if (context === undefined) {
    throw new Error('useAspectRatio must be used within an AspectRatioProvider');
  }
  return context.aspectRatio;
};
