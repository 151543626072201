import React from 'react';
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { useAspectRatio } from '../AspectRatioContext';

interface NavbarProps {
  scrollToSection: (sectionId: string) => void;
}

const Navbar: React.FC<NavbarProps> = ({ scrollToSection }) => {
  const aspectRatio = useAspectRatio();
  const isLandscape = aspectRatio >= 1;

  const handleButtonClick = (sectionId: string) => {
    scrollToSection(sectionId);
  };

  return (
    <Box bg="cinammon.light">
      <Flex
        as="nav"
        alignItems="center"
        justifyContent="space-around"
        py={6}
      >
        {isLandscape && (
          <Button onClick={() => handleButtonClick('contactUs')}>
            Contact
          </Button>
        )}
        {isLandscape && (
          <Button onClick={() => handleButtonClick('ourServices')}>
            Service
          </Button>
        )}
        <Text variant="siteTitleText">
          Sydney Lynn
        </Text>
        {isLandscape && (
          <Button onClick={() => handleButtonClick('aboutUs')}>
            Info
          </Button>
        )}
        {isLandscape && (
          <Button onClick={() => handleButtonClick('portfolio')}>
            Portfolio
          </Button>
        )}
      </Flex>
    </Box>
  );
};

export default Navbar;
