import React, { useState, useEffect } from 'react';
import { Box, Image, IconButton, Flex } from '@chakra-ui/react';
import { CloseIcon, ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

interface CarouselProps {
  images: string[];
  initialIndex: number;
  onClose: () => void;
}

const Carousel: React.FC<CarouselProps> = ({ images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth / window.innerHeight >= 1);
  const totalImages = images.length;

  const viewportHeight = window.innerHeight;
  const maxImageHeight = viewportHeight - 4 * (viewportHeight / 100); // Subtract 4vh for 2vh padding on top and bottom

  const prevIndex = (currentIndex - 1 + totalImages) % totalImages;
  const nextIndex = (currentIndex + 1) % totalImages;

  const handlePrev = () => {
    setCurrentIndex(prevIndex);
  };

  const handleNext = () => {
    setCurrentIndex(nextIndex);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth / window.innerHeight >= 1);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    // Preload the first, second, and last images initially
    const preloadImages = [images[0], images[1], images[totalImages - 1]];
    preloadImages.forEach((src) => {
      const img = new window.Image();
      img.src = src;
    });
    // Preload remaining images after a short delay
    setTimeout(() => {
      images.forEach((src) => {
        if (!preloadImages.includes(src)) {
          const img = new window.Image();
          img.src = src;
        }
      });
    }, 1000);
  }, [images, totalImages]);

  return (
    <Box
      position="fixed"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.5)"
      display="flex"
      justifyContent="center"
      alignItems="center"
      zIndex="1000"
    >
      <IconButton
        position="absolute"
        top="4"
        right="4"
        aria-label="Close"
        icon={<CloseIcon />}
        onClick={onClose}
        zIndex="1100"
        bg="cinammon.light"
        color="cinammon.dark"
        _hover={{
          bg: "cinammon.dark",
          color: "cinammon.light",
        }}
      />
      {isLandscape ? (
        <Flex alignItems="center" justifyContent="space-evenly" height="100%" width="100%">
          <Box
            onClick={handlePrev}
            opacity="0.5"
            mx={2}
            width="calc((100% - 4vh) / 4.5)"
            height="40%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            _hover={{
              opacity: 1,
              bg: "blackAlpha.600",
              cursor: "pointer",
            }}
          >
            <Image
              src={images[prevIndex]}
              alt="Previous Image"
              objectFit="cover"
              width="100%"
              height="100%"
            />
          </Box>
          <Box
            mx={2}
            width={`calc((100% - 4vh) / 2)`}
            height={`calc(100% - 4vh)`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <Image
              src={images[currentIndex]}
              alt="Current Image"
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Box>
          <Box
            onClick={handleNext}
            opacity="0.5"
            mx={2}
            width="calc((100% - 4vh) / 4.5)"
            height="40%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            _hover={{
              opacity: 1,
              bg: "blackAlpha.600",
              cursor: "pointer",
            }}
          >
            <Image
              src={images[nextIndex]}
              alt="Next Image"
              objectFit="cover"
              width="100%"
              height="100%"
            />
          </Box>
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center" height="100%">
          <Box
            mx={2}
            width={`calc(1.5 * (100% - 4vh) / 2)`}
            height={`calc(100% - 4vh)`}
            display="flex"
            justifyContent="center"
            alignItems="center"
            overflow="hidden"
          >
            <Image
              src={images[currentIndex]}
              alt="Current Image"
              width="100%"
              height="100%"
              objectFit="cover"
            />
          </Box>
          <IconButton
            aria-label="Previous"
            icon={<ArrowBackIcon />}
            position="absolute"
            left="4"
            size="lg"
            zIndex="1100"
            bg="cinammon.light"
            color="cinammon.dark"
            _hover={{
              bg: "cinammon.dark",
              color: "cinammon.light",
            }}
            onClick={handlePrev}
          />
          <IconButton
            aria-label="Next"
            icon={<ArrowForwardIcon />}
            position="absolute"
            right="4"
            size="lg"
            zIndex="1100"
            bg="cinammon.light"
            color="cinammon.dark"
            _hover={{
              bg: "cinammon.dark",
              color: "cinammon.light",
            }}
            onClick={handleNext}
          />
        </Flex>
      )}
    </Box>
  );
};

export default Carousel;
