// src/components/Gallery.tsx
import React, { useState } from 'react';
import { Box, Grid, Image, Text } from '@chakra-ui/react';
import Carousel from './Carousel';
import { ImageItem, getImages } from '../utils/getImages';

interface GalleryProps {
  images: ImageItem[];
}

const Gallery: React.FC<GalleryProps> = ({ images }) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([]);
  const [carouselOpen, setCarouselOpen] = useState(false);
  const [initialIndex, setInitialIndex] = useState(0);

  const handleOpenCarousel = async (folder: string, index: number) => {
    const fetchedImages = await getImages(folder);
    setSelectedImages(fetchedImages);
    setInitialIndex(index);
    setCarouselOpen(true);
  };

  const handleCloseCarousel = () => {
    setCarouselOpen(false);
  };

  return (
    <Box p={4}>
      <Text variant="titleText" textAlign="left">
        Gallery
      </Text>
      <Grid
        templateColumns={['1fr', 'repeat(2, 1fr)']}
        gap={6}
        mt={4}
      >
        {images.map((imageItem, index) => (
          <Box
            key={index}
            as="figure"
            position="relative"
            _hover={{
              opacity: 0.5, // Change opaqueness on hover
            }}
            onClick={() => handleOpenCarousel(imageItem.folder, index)}
            width="100%"
            paddingTop="100%" // Aspect ratio 1:1 (square)
            overflow="hidden"
          >
            <Image
              src={imageItem.img}
              alt={`Gallery image ${index + 1}`}
              objectFit="cover"
              width="100%"
              height="100%"
              position="absolute"
              top="0"
              left="0"
            />
          </Box>
        ))}
      </Grid>
      {carouselOpen && (
        <Carousel
          images={selectedImages}
          initialIndex={initialIndex}
          onClose={handleCloseCarousel}
        />
      )}
    </Box>
  );
};

export default Gallery;
