// src/components/MainPhoto.tsx
import React from 'react';
import { Box, Image } from '@chakra-ui/react';
import mainPhoto from '../assets/images/mainPhoto.jpg'; // Import the image

const MainPhoto: React.FC = () => {
  return (
    <Box>
      <Image src={mainPhoto} alt="Main Photo" />
    </Box>
  );
};

export default MainPhoto;
